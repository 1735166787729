<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (userApi.arror = false)"
    ></AError>
    <v-card class="mx-auto pa-3 mb-3" max-width="800" outlined>
      <div class="d-flex justify-space-between align-center">
        <div class="text-h6">{{ classroom.name }}</div>
      </div>
    </v-card>
    <ShareLink type="classroom" :id="this.$route.params.id"></ShareLink>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Dashboard</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-list>
            <v-list-item
              v-for="(item, i) in menus"
              :key="i"
              class="menu-item"
              @click="clickMenu(item)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="i < 4">{{ item.count }}</v-list-item-icon>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import ShareLink from "../../components/Dashboard/ShareLink.vue";
export default {
  components: {
    ShareLink,
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    classroom: {},
    gameUrl: null,
    totalPage: 0,
    search: "",
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    menus: [
      { text: "Homeworks", icon: "mdi-bag-personal", route: null, count: 0 },
      {
        text: "Students",
        icon: "mdi-account-group",
        route: "PageEducatorClassroomStudent",
        count: 0,
      },
      {
        text: "Classroom Info",
        icon: "mdi-information-outline",
        route: "PageEducatorClassroomRead",
        count: null,
      },
    ],
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Name", value: "name" },
      { text: "", value: "button", align: "end" },
    ],
    desserts: [],
  }),
  created() {
  
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/classroom/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.classroom = resp;
      this.menus[1].count = this.classroom.studentCount;
      this.updateBreadcrumb(resp)
    };
    this.gameUrl = `${process.env.VUE_APP_URL_WEB_GAME}/en/access?code=${this.auth.Group.code}`;
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageEducatorDashboard" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: resp.name,
      to: { name: "PageEducatorClassroomDashboard" },
      exact: true,
    });
    },
    handleClick(item) {
      this.$router.push({
        name: "PageEducatorInstitutionClassroomStudent",
        params: { parentId: this.$route.params.id, id: item.id },
      });
      console.log(item);
    },
    clickMenu(item) {
      this.$router.push({
        name: item.route,
        params: { parentId: this.$route.params.id },
      });
    },
   
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>